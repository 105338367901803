import React from 'react';
import FiveStar from './FiveStar';

function RateInput(props) {
  return (
    <div className="flex relative pb-1">
      <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div className="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">
        <p>{props.qkey}</p>
      </div>
      <div className="flex-grow pl-4">
        <p className="leading-relaxed mb-1">{props.title}</p>
        <div className="flex items-center mb-1">
          <FiveStar rating={props.answer} questionId={props.questionId} />
        </div>
      </div>
    </div>
  );
}

export default RateInput;
