import Questionnaire from './components/Questionnaire';
import Header from './components/Header';
import './App.css';
import { useEffect, useState } from 'react';
import { AnswersContext } from './context/AnswersContext';

function App() {
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    document.title = 'Две Атмосферы - контроль качества';
  }, []);

  return (
    <AnswersContext.Provider value={{ answers, setAnswers }}>
      <Header />
      <section className="text-gray-600 body-font max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl q-section-bg">
        <div className="container px-5 py-10 mx-auto flex flex-wrap justify-center">
          <div className="flex flex-wrap w-full">
            <div className="w-full">
              <Questionnaire />
            </div>
            {/* <img className="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12" src="https://dummyimage.com/1200x500" alt="step" /> */}
          </div>
        </div>
      </section>
    </AnswersContext.Provider>
  );
}

export default App;
