import React, { useContext, useState } from 'react';
import { AnswersContext } from '../../../context/AnswersContext';

function BoolInput(props) {
  const { answers, setAnswers } = useContext(AnswersContext);
  const [answer, setAnswer] = useState(null);

  function handleChange(e) {
    const index = answers.findIndex(obj => obj.id === props.questionId);

    if (index === -1) {
      setAnswers([
        ...answers,
        {
          id: props.questionId,
          answer: e.target.value,
        },
      ]);
      setAnswer({ answer: e.target.value });
    } else {
      // setAnswers([
      //   ...answers,
      //   {
      //     id: props.qkey,
      //     answer: e.target.value,
      //   },
      // ]);
    }
  }

  return (
    <div className="flex relative">
      <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
        <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
      </div>
      <div className="flex-shrink-0 w-10 h-10 rounded-full bg-green-500 inline-flex items-center justify-center text-white relative z-10">
        <p>{props.qkey}</p>
      </div>
      <div className="flex-grow pl-4">
        <p className="leading-relaxed">{props.title}</p>
        <ul className="grid grid-cols-2 gap-x-5 m-2">
          <li className="relative">
            <input
              value="1"
              checked={answer?.answer === '1'}
              onChange={handleChange}
              className="sr-only peer"
              type="radio"
              name={'question-' + props.qkey}
              id={'question-' + props.qkey + '-yes'}
            />
            <label
              className="flex p-2 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-green-500 peer-checked:ring-2 peer-checked:border-transparent"
              htmlFor={'question-' + props.qkey + '-yes'}
            >
              Да
            </label>
            <div className="absolute hidden w-4 h-4 peer-checked:block top-2 right-3">
              👍
            </div>
          </li>
          <li className="relative">
            <input
              value="0"
              checked={answer?.answer === '0'}
              onChange={handleChange}
              className="sr-only peer"
              type="radio"
              name={'question-' + props.qkey}
              id={'question-' + props.qkey + '-no'}
            />
            <label
              className="flex p-2 bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-red-500 peer-checked:ring-2 peer-checked:border-transparent"
              htmlFor={'question-' + props.qkey + '-no'}
            >
              Нет
            </label>
            <div className="absolute hidden w-4 h-4 peer-checked:block top-3 right-3">
              👎
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BoolInput;
