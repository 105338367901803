import React from 'react';

function Header() {
  return (
    <nav className="border-gray-200 px-2 sm:px-4 py-2.5 bg-green-500">
      <div className="container flex flex-wrap justify-center items-center mx-auto">
        <div className="flex items-center nav-logo"></div>
        <div className="self-center text-xl font-semibold whitespace-nowrap text-white px-2">
          Две Атмосферы
        </div>
      </div>
    </nav>
  );
}

export default Header;
