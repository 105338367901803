import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import RateInput from './Inputs/RateInput/RateInput';
import BoolInput from './Inputs/BoolInput/BoolInput';
import { AnswersContext } from '../context/AnswersContext';
import axios from 'axios';

function Questionnaire() {
  const {
    data: questionnaire,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useQuery('questionnaire', fetchQuestionnaire);

  const { answers } = useContext(AnswersContext);
  const [isSended, setSended] = useState(null);
  const [badMarks, setBadMarks] = useState(null);
  const [tipsLink, setTipsLink] = useState(null);
  const [isHomepage, setIsHomepage] = useState(false);

  useEffect(() => {
    const queryParams = window.location.pathname;

    if (
      !isHomepage &&
      isSuccess &&
      typeof questionnaire != 'undefined' &&
      answers.length === questionnaire.questions.length &&
      !isSended
    ) {
      axios
        .put(`${process.env.REACT_APP_API_URL}/feedbacks${queryParams}`, {
          answers: answers,
        })
        .then(response => {
          setBadMarks(response.data.badMarks);
          setTipsLink(response.data.tipsLink);
          setSended(true);
          setIsHomepage(false);
        });
      setSended(true);
    }

    // if (!isLoading && isSuccess && typeof questionnaire !== 'undefined') {
    //   if (
    //     questionnaire.questions.find(question => {
    //       return question.answer != null;
    //     })
    //   ) {
    //     setSended(true);
    //   } else {
    //     setSended(false);
    //   }
    // }
  }, [
    isLoading,
    isSended,
    isSuccess,
    isHomepage,
    answers,
    questionnaire,
    setBadMarks,
    setTipsLink,
  ]);

  const queryParams = window.location.pathname;

  function fetchQuestionnaire() {
    if (queryParams === '/') {
      setIsHomepage(true);
    } else {
      return fetch(`${process.env.REACT_APP_API_URL}/feedbacks${queryParams}`)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if (
            data.questions.find(question => {
              return question.answer != null;
            })
          ) {
            setSended(true);
          } else {
            setSended(false);
          }
          return data;
        });
    }
  }

  return (
    <div>
      {isHomepage && (
        <div>
          Для оценки работы мастерской перейдите по ссылке из полученного
          сообщения.
        </div>
      )}
      {isLoading && <div>Загрузка анкеты...</div>}
      {!isHomepage &&
        isSuccess &&
        !isSended &&
        typeof questionnaire != 'undefined' && (
          <form action="">
            <ul>
              {questionnaire.questions.map((question, index) => (
                <li key={question.id}>
                  {question.type === 'Grade' && (
                    <RateInput
                      qkey={index + 1}
                      title={question.title}
                      questionId={question.id}
                      answer={question.answer}
                    />
                  )}
                  {question.type === 'Normal' && (
                    <BoolInput
                      qkey={index + 1}
                      title={question.title}
                      questionId={question.id}
                      // answer={question.answer}
                    />
                  )}
                </li>
              ))}
            </ul>
          </form>
        )}
      {isError && (
        <div>
          <h1 className="font-xl font-bold">Ошибка 404</h1>Анкета не найдена,
          проверьте правильность ссылки
          {console.log(error.message)}
        </div>
      )}
      {isSended && (
        <div className="grid justify-items-center">
          <p className="pb-5">Спасибо за оценки!</p>
          {badMarks === 1 && (
            <p className="text-center">
              Мы обязательно учтем Ваши оценки и улучшим качество сервиса.
            </p>
          )}
          {badMarks === 0 && (
            <a
              href={`${tipsLink}`}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
            >
              Оставить чаевые мастеру
            </a>
          )}
        </div>
      )}
    </div>
  );
}

export default Questionnaire;
