import React, { useContext, useEffect, useState } from 'react';
import { AnswersContext } from '../../../context/AnswersContext';
import Star from './Star';

function FiveStar(props) {
  const { answers, setAnswers } = useContext(AnswersContext);
  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    getRatingStars(answer);
  });

  const getRatingStars = stars => {
    const content = [];

    for (let i = 0; i < 5; i++) {
      content.push(
        <div
          key={i}
          onClick={() => {
            handleClick(i);
          }}
        >
          <Star active={answer > i} key={i} />
        </div>
      );
    }
    return content;
  };

  function handleClick(rating) {
    const index = answers.findIndex(obj => obj.id === props.questionId);

    if (index === -1) {
      setAnswers([
        ...answers,
        {
          id: props.questionId,
          answer: rating + 1,
        },
      ]);
      setAnswer(rating + 1);
      getRatingStars(rating + 1);
    } else {
    }
  }

  return <div className="flex items-center mb-4">{getRatingStars(answer)}</div>;
}

export default FiveStar;
